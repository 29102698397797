import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id="experience">
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience_container">
        <div className="experience_frontend">
          <h3>Frontend Development</h3>
          <div className="experience_content">
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>HTML</h4>
              <small className="text-light">Intermediate</small>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>CSS</h4>
              <small className="text-light">Intermediate</small>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>Javascript</h4>
              <small className="text-light">Intermediate</small>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>Bootstrap</h4>
              <small className="text-light">Intermediate</small>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>React</h4>
              <small className="text-light">Beginner</small>
            </article>
          </div>
        </div>

        <div className="experience_backend">
          <h3>Backend Development</h3>
          <div className="experience_content">
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>Node JS</h4>
              <small className="text-light">Intermediate</small>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>MongoDB</h4>
              <small className="text-light">Beginner</small>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>Python</h4>
              <small className="text-light">Beginner</small>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>MySQL</h4>
              <small className="text-light">Basic</small>
            </article>
          </div>
        </div>

        <div className="experience_machineLearning">
          <h3>Machine Learning</h3>
          <div className="experience_content">
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>Scikit-Learn</h4>
              <small className="text-light">Intermediate</small>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>Tensorflow</h4>
              <small className="text-light">Beginner</small>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>Keras</h4>
              <small className="text-light">Beginner</small>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>PyTorch</h4>
              <small className="text-light">Beginner</small>
            </article>
            <article className="experience_details">
              <BsPatchCheckFill className="experience_detail-icon" />
              <h4>Jupyter Notebook</h4>
              <small className="text-light">Intermediate</small>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experience